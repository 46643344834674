const messages = {
  pt: {
    translations: {
      headings: {
        dashboard: "Dashboard",
        forecast: {
          title: "Forecast",
          downloads: "Downloads",
          analysis: "Análise",
        },
        automations: "Automações",
        settings: "Configurações",
      },
      sideBar: {
        editAvatarHeading: "Editar sua foto",
        addAvatar: "Adicionar foto",
        deleteAvatar: "Excluir foto",
      },
      languageSelector: "Idioma",
      company: "Empresa",
      login: {
        title: "Acesse nossa plataforma",
        emailLabel: "Seu e-mail",
        emailPlaceholder: "exemplo@empresa.com.br",
        passwordLabel: "Sua senha",
        passwordPlaceholder: "minhasenha",
        signIn: "Entrar",
        error: "Usuário e/ou senha inválidos!",
      },
      settings: "Configurações",
      myAccount: "Minha conta",
      users: "Usuários",
      organization: "Organização",
      logout: "Sair",
      profilePicture: "Foto do perfil",
      chosePicture: "Escolher foto",
      permissions: "Permissões",
      user: "Usuário",
      role: "Papel",
      productHierarchy: "Hierarquia de produtos",
      numberOfLevels: "Quantidade de níveis",
      level: "Nível",
      levelName: "Nome do nível",
      channelRegistration: "Cadastro de canais",
      // FORECAST SECTION:
      year: "Ano",
      month: "Mês",
      weeks: "Semanas",
      months: "Meses",
      filterBy: "Filtrar por",
      generatedOn: "Predição gerada em",
      generationDate: "Data de geração",
      forecastDate: "Data do forecast",
      commercialWeek: "Semana comercial",
      commercialWeeks: "Semanas comerciais",
      monthlyForecast: "Forecast mensal",
      showFilter: "Filtros",
      hideFilter: "Ocultar filtros",
      removeFilters: "Remover filtros",
      products: "Produtos",
      fileError: "O arquivo selecionado está indisponível. Selecione datas diferentes",
      summary: "Resumo do download",
      singleFile: "Arquivo único (.xlsx)",
      singleFileCaption: "Uma aba por semana",
      multipleFiles: "Arquivos separados (.zip)",
      multipleFilesCaption: "Um arquivo por semana",
      exportMultiple: "Exportar vários",
      advancedExport: "Exportação avançada",
      forecasts: "Forecasts",
      selectCommWeek: "Forecasts gerados nas seguintes semanas comerciais:",
      next: "Próximo",
      changeWeekModal: {
        body: "Se você alterar as semanas comerciais selecionadas, os filtros de produto aplicados serão resetados.",
      },
      attention: "Atenção!",
      options: "Opções",
      downloadSummary: "Resumo do download",
      addAnotherMale: "Adicionar outro",
      addAnotherFemale: "Adicionar outra",
      addPeriod: "Adicionar período",
      commercialCalendar: "Calendário Comercial",
      insertYear: "Insira um ano entre 1000 e 9999",
      sort: "Ordenar",
      graphicHeading: "Análise Gráfica",
      descriptiveHeading: "Análise Descritiva",
      classicHeading: "Análise Clássica",
      productFilter: "Filtrar por produto",
      brand: "Coleção",
      griffe: "Griffe",
      grife: "Grife",
      line: "Linha",
      group: "Grupo",
      subgroup: "Subgrupo",
      marca: "Marca",
      colecao: "Coleção",
      linha: "Linha",
      grupo: "Grupo",
      subgrupo: "Subgrupo",
      subgrupos: "Subgrupos",
      graphType: "Estilo de gráfico",
      lines: "Linhas",
      columns: "Colunas",
      showing: "Mostrando",
      product: "Produto",
      estimated: "Previsto",
      realized: "Realizado",
      percentDiff: "Dif %",
      diff: "Diferença %",
      wape: "WAPE",
      realizado: "Realizado",
      selectOneOption: "Selecione uma opção",
      selectAtLeastOne: "Selecione ao menos uma opção",
      sortBy: "Ordenar por",
      highest: "Maior",
      lowest: "Menor",
      // Página "Análise Descritiva"
      aidronView: "Visualização Aidron",
      classicView: "Visualização Clássica",
      resultsSubtitle: "Previsão de demanda (em peças)",
      week: "Semana",
      all: "Todos",
      choseProducts: "Escolher produtos",
      checkAll: "Marcar todos",
      uncheckAll: "Desmarcar todos",
      analyseGraph: "Analisar gráfico",
      analyse: "Analisar",
      close: "Fechar",
      estActDif: "Prev  |  Real  |  Dif%",
      centerMonth: "Centralizar mês",
      centerWeek: "Centralizar semana",
      expand: "Expandir dados",
      shrink: "Reduzir dados",
      selectedProducts: "Produtos selecionados",
      // Página "Visualização Clássica"
      hideColumns: "Ocultar colunas",
      unhideColumns: "Reexibir colunas",
      filterByDate: "Filtrar por data",
      filterByName: "Filtrar por nome",
      searchLabel: "Buscar produto...",
      searchError: "Sua busca não retornou resultados.",
      exportData: "Exportar dados",
      aidronForecast: "ForecastAidron",
      changeCommWeek: "Alterar semana comercial",
      editEstimated: "Editar previsão",
      finishRevision: "Finalizar revisão",
      revision: "Revisão",
      revised: "revised",
      send: "Enviar",
      reviewAndSend: "Revisar e enviar",
      typeYear: "Digite o ano...",
      forecastPeriod: "Período de predição",
      showHistory: "Exibir histórico",
      analyseAndIntegratePredictions: "Analisar e integrar as predições",
      analysePredictions: "Analisar as predições",
      saveRevision: "Salvar revisão",
      closeRevision: "Fechar revisão",
      cancel: "Cancelar",
      dontSave: "Descartar alterações",
      forecastRevision: "Revisão de predições",
      unsavedChangesModal: {
        heading: "Salvar alterações?",
        body: "Existem alterações não salvas. Deseja salvar antes de continuar?",
        promptBody: "Existem alterações não salvas. Tem certeza que deseja sair?",
      },
      dataSent: "Dados enviados com sucesso!",
      sent: "Enviado",
      submittedOn: "Enviado em",
      sendingPreviewModal: {
        heading: "Resumo do envio",
        alreadySentProducst:
          "Você selecionou itens que já foram enviados anteriormente, portanto estes foram retirados da lista abaixo.",
        body: "Revise os dados a serem enviados. Para editar, volte à página anterior.",
      },
      noSendingIntegrationModal: {
        heading: "Não há integração com seu sistema de planejamento!",
        body: "Você não possui integração com seu sistema de planejamento configurada. Geramos um novo arquivo de exportação para você com esta versão do Forecast.",
        downloadFile: "Baixar arquivo",
      },
      back: "Voltar",
      formerWeekModal: {
        heading: "Confirme a semana comercial",
        bodyEditing: "Você está analisando as predições geradas na semana ",
        bodySending: "As predições que você está prestes a enviar para o seu sistema foram geradas na semana ",
        body2: " de ",
        body3: ". Esta não é a predição mais recente.",
        question: "Como deseja continuar?",
        stay: "Continuar na semana ",
        goToCurrent: "Ir para a semana mais recente",
      },
      savedUnsent: "Salvo e não enviado",
      seeSendingStatus: "Ver status envio",
      sendingStatus: "Status de envio",
      productsAmount: "Nº de produtos",
      seeDetails: "Ver detalhes",
      hideDetails: "Ocultar detalhes",
      noCheckedProductsModal: {
        heading: "Nenhum produto selecionado!",
        body: "Para continuar, selecione um ou mais produtos da tabela.",
      },
      productRegistration: "Cadastro de produtos",
      remove: "Remover",
      hideProducts: "Ocultar produtos",
      showRegisteredProducts: "Ver produtos cadastrados",
      saveModifications: "Salvar alterações",
      errorModal: {
        heading: "Ops! Algo deu errado",
        checkFields: "Corrija os campos destacados e tente novamente.",
        changesNotSaved: "Não foi possível salvar suas alterações. Contate o administrador do sistema.",
      },
      typeValidName: "Insira um nome válido",
      savedModal: "As alterações foram salvas!",
      season: "Estação",
      seasonsRegistration: "Cadastro de estações",
      hideSeasons: "Ocultar estações",
      showRegisteredSeasons: "Ver estações cadastradas",
      and: "e",
      filterBySeason: "Filtrar por estação",
      predictions: "Predições",
      showSeasons: "Exibir estações",
      bulkImport: "Importar em lote",
      seasonsBulkImport: "Importar estações em lote",
      step: "Passo",
      importModal: {
        step1Instruction: "Baixe o template a ser preenchido conforme o exemplo nele contido.",
        step2Instruction: "Faça upload do template preenchido com os dados que você deseja cadastrar.",
        step3Instruction: "Verifique os dados a serem importados e confirme a importação.",
        error:
          "Houve um problema na importação dos dados. Verifique os dados contidos no arquivo e, se o problema persistir, contate o administrador do sistema.",
      },
      choseFile: "Escolher arquivo",
      fileUploaded: "Arquivo carregado!",
      confirm: "Confirmar",
      seasonNumber: "Número da estação",
      seasonNumbers: "Números das estações",
      seasonName: "Nome da estação",
      seasonNames: "Nomes das estações",
      seasonMonths: "Meses das estações",
      importSeasons: "Importar estações",
      importProducts: "Importar produtos",
      percentDifference: "Diferença percentual",
      productNumber: "Número do produto",
      example: "Exemplo",
      optional: "Opcional",
      consultOnSeasonsSheet: 'Consultar número na aba "Números das estações" deste arquivo',
      new: "Novo",
      seasons: "Estações",
      wereSaved: "foram salvos(as)",
      wereNotSaved: "NÃO foram salvos(as)",
      verifyData: "Verifique os dados inseridos e tente novamente. Se o problema persistir, contate o administrador do sistema.",
      simulateActions: "Simular ações",
      simulateActionsSubtitle: "Simule diversos cenários futuros em tempo real",
      exploreScenarios: "explore cenários internos e externos",
      actionsCheckout: "Checkout de Ações",
      actionsCheckoutSubtitle: "Ações marcadas para ajuste",
      salesOpportunities: "Oportunidades de Vendas",
      buyOptimization: "Otimização de Compra",
      storesOpportunities: "Oportunidades de Lojas",
      comingSoon: "Em breve",
      opportunity: "Oportunidade",
      opportunities: "Oportunidades",
      inCheckout: "Em Checkout",
      stockOrSalesIncreaseOptimization: "Otimização de estoque ou aumento de vendas",
      totalOpportunities: "Total de Oportunidades",
      costReduction: "redução de custo",
      channels: "Canais",
      adjustingPlanAndStock: "ajustando plano e estoque",
      assortmentAdjustment: "Ajuste de sortimento",
      additionalSale: "Venda Adicional",
      addingStockAndSales: "somando estoque e vendas",
      above: "acima",
      below: "abaixo",
      productPlan: "Plano de produto",
      piecesOfClothingAbbreviation: "pçs",
      theForecast: "do Forecast",
      paretoAnalysis: "Análise de Pareto",
      ofTheTotal: "do total",
      eightyPercentOfGap: "produtos somam 80% do gap",
      channelsTarget: "Meta dos canais",
      waitingForData: "Aguardando dados",
      currentPlanVsForecast: "Plano atual x Forecast",
      monthsSumDifference: "Diferença em relação à soma de todos os meses",
      productOpportunities: "Oportunidades de Produto",
      channelOpportunities: "Oportunidades de Canais",
      marketInsights: "Insights de Mercado",
      analysis: "Análise",
      productPlanVsForecast: "Produto: Plano x Forecast",
      channelsTargetVsForecast: "Canais: Meta x Forecast",
      forecastAccuracy: "Assertividade do Forecast",
      actions: "Ações",
      adjustProductPlan: "Ajustar Plano de Produto",
      avoidSalesLoss: "Evitar Perda de Venda",
      optimizePurchase: "Otimizar a Compra",
      highestValue: "maior valor",
      lowestValue: "menor valor",
      showAllOpportunities: "Exibir todas as oportunidades",
      opportunityType: "Tipo de Oportunidade",
      orderBy: "Ordenar por",
      done: "Concluído",
      additionalSaleExplanation:
        "Esta oportunidade é mapeada sempre que o seu plano de vendas futuro esta abaixo do centro da demanda do cliente (a demanda do cliente por este produto pode ser observada em verde no gráfico).",
      expectedIncreaseInSales: "Aumento Esperado da Venda",
      avoidSalesLossExplanation:
        "Esta oportunidade é identificada sempre que as vendas dos últimos meses estiverem abaixo ou próximo do limite mínimo da demanda do cliente.",
      optimizePurchaseExplanation:
        "Esta oportunidade é mapeada sempre que o seu plano de vendas futuro está acima do limite máximo da demanda por este produto. No gráfico, este limite da demanda pode ser observado em verde.",
      futurePurchaseReduction: "Redução da Compra Futura",
      active: "Ativo",
      reactivated: "Reativado",
      checkout: "Checkout",
      percent: "Percentual",
      opportunitySize: "Tamanho da Oportunidade",
      status: "Status",
      deletion: "Exclusão",
      reopen: "Reabrir",
      wishReopenOpportunity: "Deseja reabrir a oportunidade?",
      opportunityWillBeAnalysed: "Oportunidade será analisada",
      opportunityWillCheckout: 'Ao selecionar esta opção, a oportunidade receberá o status "Checkout".',
      opportunityHasGoneCheckout: "A oportunidade foi enviada para Checkout de Ações!",
      notAnOpportunity: "Não é uma oportunidade",
      describeDeletionReason:
        "Ao selecionar esta opção, você poderá descrever o motivo da exclusão, e a oportunidade será removida da lista de oportunidades.",
      opportunityHasBeenRemoved: "A oportunidade foi removida.",
      removeFromCheckout: "Retirar do Checkout",
      opportunityWillRemainInList:
        'Ao selecionar esta opção, a oportunidade permanecerá na lista de oportunidades com o status "Ativo".',
      opportunityHasLeftCheckout: "A oportunidade foi retirada da lista de Checkout.",
      errorContactAdmin: "Ocorreu um erro. Por favor, tente novamente ou contate o administrador do sistema",
      finish: "Finalizar",
      describeReason: "Descreva brevemente o motivo",
      remainingCharacters: "Caracteres restantes",
      transforInAction: "Transformar em ação",
      removeOpportunity: "Descartar Oportunidade",
      save: "Salvar",
      productHasOtherOpportunity: "Este produto possui outra oportunidade",
      productHasOtherOpportunities: "Este produto possui outras oportunidades",
      analyseOpportunity: "Analisar Oportunidade",
      analyseOpportunities: "Analisar Oportunidades",
      actionRecommendation: "Recomendação de ação",
      advancedAnalysis: "Análise Avançada",
      currentPlan: "Plano Atual",
      opportunityChartError:
        "Ocorreu um erro ao carregar o gráfico desta oportunidade. Por favor, contate o administrador do sistema.",
      willBeListedAsOpportunity: "Ela voltará a ser listada como Oportunidade e seu valor contará no total ativo.",
      removedOpportunities: "Oportunidades Excluídas",
      opportunityWasReopened: "A oportunidade foi reaberta.",
      noRemovedOpportunities: "Não há oportunidades excluídas.",
      period: "Período",
      opportunityTypeTitle: "Oportunidade de {{opportunityTitle}}",
      help: "Ajuda",
      planVsForecastSubtitle:
        "Análise das principais divergências entre o Plano oficial da empresa X Forecast gerado pela Aidron",
      planVsForecastAnalysis: "Análise do Plano Atual x Forecast",
      mainGrouping: "Agrupamento Principal",
      salesImpact: "Impacto na Venda",
      factorsThatImpactSales: "Fatores que Impactam a Venda",
      groupingTitle: "agrupamento {{groupingName}}",
      planVsForecast: "Plano x Forecast",
      monthlyBreakdown: "Detalhamento mensal",
      aiForecast: "Forecast IA",
      greatPerformance: "Ótima performance",
      ofAboveThanDemandProducts: "dos produtos com venda acima da demanda",
      criticalPoints: "Pontos de atenção",
      ofBelowThanDemandProducts: "dos produtos com venda abaixo da demanda",
      productDriversListingAndPercent: "Abaixo, a relação dos fatores que impactam na venda e o percentual de impacto de cada um",
      upcomingFeature: "Esta funcionalidade estará disponivel em breve.",
      mainGroupingBreakdown: "Quebra do Agrupamento Principal",
      total: "Total",
      analyzeProducts: "Analisar produtos",
      accuracyAnalysis: "Análise de Assertividade",
      activeOpportunities: "Oportunidades Ativas",
      possibleUpcomingFeature: "Em breve esta funcionalidade pode estar disponível para sua empresa.",
      productDrivers: "Fatores que impactam a venda deste produto",
      productDriversExplanation:
        "Esta análise identifica % de impacto de cada fator na venda deste produto. Ela é importante para dignóstico e proposição de ações que possam otimizar estoque e/ou aumentar as vendas.",
      realizedDeviationVsDemand: "Desvio Executado x Demanda",
      realizedDeviationVsDemandDescription:
        "Média percentual dos desvios do realizado em relação à demanda do cliente nos últimos dois meses. Quanto maior for este indicador, menos previsível é este item e isso pode indicar problemas de operação que precisamos resolver para tornar nosso plano mais assertivo.",
      lastMonthSaleVariation: "Variação da venda no último mês",
      lastMonthSaleVariationDescription:
        "Variação percentual entre a venda realizada no penúltimo e último mês. Quando um pico de variação ocorre (e não se trate de uma data especial que ocorreu entre um mês e outro) isso pode nos indicar um cenário de mudança repentina não prevista.",
      saleAnomalyProbability: "Probabilidade de problema na venda",
      saleAnomalyProbabilityDescription:
        "Este valor indica a probabilidade que a IA encontrou da venda histórica ter algum problema de operação, ou seja, se este índice for alto, isso indica que a variação da venda no último mês não reflete apenas um comportamento da demanda dos clientes, mas sim alguma ação da operação (ex.: mudança de preço, estratégia de sortimento, etc).",
      aiAccuracyOverClient: "Diferença Acerto IA x Plano",
      aiAccuracyOverClientDescription:
        "Se o número for positivo, significa que IA acerta mais que o Plano nos últimos 6 meses. Se for zero, significa que não há diferença no total deste período. E se for negativo, isso significa que a IA acerta menos que o plano. No entanto se a assertividade for baixa e a probabilidade de problema for alta, possivelmente temos fatores de operação que não estão nas variáveis que a IA utiliza e temos de incluir para aperfeiçoar os modelos.",
      indexesToSupport: "Índices para apoio na análise",
      productView: "Visão por produto",
      forecastGeneratedIn: "Forecast gerado em",
      indicator: "Indicador",
      searchByHierarchy: "Buscar por hierarquia",
      type: "Digite",
      filterByHierarchy: "Filtrar por hierarquia",
      clearFilters: "Limpar filtros",
      analyzeProduct: "Analisar produto",
      salesHistory: "Histórico de Vendas",
      freezeColumns: "Congelar colunas",
      choseColumns: "Escolher colunas",
      "Precipitação (Quantidade de chuvas)": "Precipitação (Quantidade de chuvas)",
      "Índice Geral de Preços (IGPM)": "Índice Geral de Preços (IGPM)",
      "Índice de confecção de artigos do vestuário e acessórios": "Índice de confecção de artigos do vestuário e acessórios",
      "Índice de importação de itens de vestuário": "Índice de importação de itens de vestuário",
      Preço: "Preço",
      Margem: "Margem",
      "Índice de vendas reais no varejo": "Índice de vendas reais no varejo",
      "Índice de vendas reais no varejo do vestuário": "Índice de vendas reais no varejo do vestuário",
      Temperatura: "Temperatura",
      "Brasileiros comprando itens de vestuário fora do Brasil": "Brasileiros comprando itens de vestuário fora do Brasil",
      Chuva: "Chuva",
      "Produção de artigos do vestuário e acessórios no Brasil": "Produção de artigos do vestuário e acessórios no Brasil",
      "Venda do Varejo de vestuário Brasil": "Venda do Varejo de vestuário Brasil",
      "Venda do Varejo no Brasil": "Venda do Varejo no Brasil",
      "Fluxo de Clientes (Índices do Varejo no Brasil)": "Fluxo de Clientes (Índices do Varejo no Brasil)",
      percentParticipation: "Participação %",
      ai: "IA",
      differenceAidronAndClient: "Dif IA x Plano Atual",
      percentAidronVsClient: "Dif% IA x Plano Atual",
      partPercent: "Part %",
      maxPot: "Pot Máx",
      minPot: "Pot Mín",
      maxPotential: "Potencial Máximo",
      minPotential: "Potencial Mínimo",
      projection: "Projeção",
      realizedSales: "Venda Realizada",
      upperLimitAbbreviation: "Lim Superior",
      upperLimit: "Limite Superior",
      lowerLimitAbbreviation: "Lim Inferior",
      lowerLimit: "Limite Inferior",
      percentDiffAIVsProjection: "Dif% IA x Projeção",
      percentDiffCurrentPlanVsProjection: "Dif% Plano Atual x Projeção",
      percentParticipationOnRealized: "Participação % no Realizado",
      percentDifferenceAIVsRealized: "Dif% IA x Realizado",
      percentDifferenceCurrentPlanVsRealized: "Dif% Plano Atual x Realizado",
      percentParticipationInRealized: "Participação % no Realizado",
      percentDifferenceAIVsStorePlan: "Dif% IA x Plano Loja",
      aiAdjustment: "Ajuste IA",
      newPlan: "Novo Plano",
      lastForecastGeneratedOn: "Último forecast gerado em",
      analyzeProductAccuracy: "Analisar Assertividade Produto",
      customerDemand: "Demanda do Cliente",
      stockOptimization: "Otimização de Estoque",
      opportunitiesCheckout: "Checkout de Oportunidades",
      checkoutSummary: "Resumo do Checkout",
      noOpportunitiesGoTo: "Nenhuma oportunidade foi enviada para Checkout. Para isso, vá para a",
      opportunitiesPage: "página de Oportunidades",
      opportunityWasFinalized: "Oportunidade finalizada",
      storeSalesIncrease: "Aumento de Vendas da Loja",
      opportunityWillBeFinalized:
        "Ao clicar em Ok, esta oportunidade será finalizada e marcada como Aplicada no Planejamento atual da empresa",
      newPhotoSaved: "Sua nova foto foi salva com sucesso.",
      newPasswordSaved: "Nova senha salva com sucesso!",
      newPasswordNotSaved:
        "Sua senha NÃO pode ser salva. Tente novamente em instantes. Se o problema persistir, contate o administrador do sistema.",
      changePassword: "Alterar senha",
      newPassword: "Nova senha",
      repeatNewPassword: "Repetir nova senha",
      savePassword: "Salvar senha",
      newPlanUnsavedChanges: "Pode haver alterações não salvas na edição do Novo Plano. Tem certeza que deseja sair?",
      adjustSalesPlan: "Ajustar Plano de Vendas",
      backToOriginalValue: "Retornar ao valor original",
      clientDemandVsPlan: "Demanda do Cliente x Plano",
      salesProblemProbability: "Probabilidade de Problema na Venda",
      differenceAIAccuracyVsPlan: "Diferença Acerto IA x Plano",
      featureComingSoon: "Esta funcionalidade estará disponivel em breve.",
      differenceFromPlan: "Diferença do Plano",
      avoidedStockSurplus: "Excesso de Estoque Evitado",
      increaseInSales: "Aumento de Vendas",
      newPlanAnalysis: "Análise do Novo Plano",
      saving: "Salvando",
      aiAdjustmentsFilter: "Filtrar Ajuste IA",
      removeFilter: "Remover filtro",
      filter: "Filtrar",
      current: "Atual",
      difference: "Diferença",
      cancelEdit: "Cancelar edição",
      edit: "Editar",
      integrate: "Integrar",
      userActivity: "Atividade de Usuários",
      opportunityAddedToCheckout: "Oportunidade adicionada ao Checkout de Ações!",
      opportunitySuccessfullyRemoved: "Oportunidade removida com sucesso!",
      flowDependencyForSelling: "Nível de dependência de Fluxo para Venda",
      flowDependencyForSellingExplanation:
        "Caso o % seja muito alto, os fatores listados acima terão pouco efeito se o fluxo de clientes não aumentar nos locais onde este produto possui uma venda alta. Para produtos com dependência de Fluxo acima de 60%, recomendamos ações na operação que tragam mais visibilidade ao produto.",
      opportunityAction: "Ação da Oportunidade",
      consolidatedView: "Visão Consolidada",
      finalizeAction: "Finalizar Ação",
      finalizeOpportunity: "Finalizar Oportunidade",
      // PÁGINA: ANÁLISE DE LOJAS
      storesAnalytics: "Análise de Lojas"
    },
  },
};

export { messages };
